<template>
  <section class="form-group">
    <form ref="form" class="container">
      <h4>{{$t('SubmitProject')}}</h4>
      <div class="list-name" ref='title'> {{$t('Title')}} <span>*</span></div>
      <input type="text" name="title" :placeholder="$t('ProjectTitle')" v-model="title" autocomplete="off">
      <div class="list-name" ref="category"> {{$t('Category')}} <span>*</span></div>
      <select :class="{selected:cateID}" name="cateID" v-model="cateID">
        <option value='' disabled selected>{{$t('SelectCategory')}}</option>
        <option v-for="item in categoryList" :value="item.ID" :key="item.ID">{{item.title}}</option>
      </select>
      <div class="list-name" ref="introduction">{{$t('ShortIntroduction')}} <span>*</span></div>
      <div class="basic-group">
        <textarea name="introduction" :placeholder="$t('ShortIntroductionLimit')" rows="4" v-model="introduction" @input="limitByteSize" autocomplete="off"></textarea>
        <span><i :class="{warn:shortByte>256}">{{shortByte}}</i>/256</span>
      </div>
      <div class="list-name">{{$t('DetailDescription')}}</div>
      <textarea name="description" :placeholder="$t('DetailDescription')" v-model="description" ref="des" @input="textareaHeight" autocomplete="off"></textarea>
      <!-- logo -->
      <div class="list-name">Logo ({{$t('ImageSize')}}: 288*288 px)</div>
      <div class="upload-pic" id="fileContainer" :style="'backgroundImage:url('+ (img?'https://oss-us-cdn.maiziqianbao.net/'+img:require('@/assets/img/icon/photo.png'))+')'">
        <div id="file"></div>
        <input type="hidden" name="img" v-model="img">
      </div>
      <div class="list-name">{{$t('Website')}}</div>
      <input type="text" name="website" :placeholder="$t('Website')" v-model="website" autocomplete="off">
      <div class="list-name">{{$t('Email')}}</div>
      <input type="email" name="email" :placeholder="$t('Email')" v-model="email" autocomplete="off">
      <div class="list-name">Twitter</div>
      <input type="text" name="twitter" placeholder="Twitter" v-model="twitter" autocomplete="off">
      <div class="list-name">Telegram</div>
      <input type="text" name="telegram" placeholder="Telegram" v-model="telegram" autocomplete="off">
      <div class="list-name">Discord</div>
      <input type="text" name="discord" placeholder="Discord" v-model="discord" autocomplete="off">
      <div class="list-name">Github</div>
      <input type="text" name="github" placeholder="Github" v-model="github" autocomplete="off">
      <div class="list-name">Coinmarketcap</div>
      <input type="text" name="coinmarketcap" placeholder="Coinmarketcap" v-model="coinmarketcap" autocomplete="off">
      <div class="list-name">{{$t('SmartContractAddresses')}}</div>
      <textarea name="contractAddresses" :placeholder="$t('SmartContractAddressesPlaceholder')" rows="4" v-model="scAddress" autocomplete="off"></textarea>
      <div class="list-name">{{$t('TokenSymbol')}}</div>
      <input type="text" name="tokenSymbol" :placeholder="$t('TokenSymbol')" v-model="tokenSymbol" autocomplete="off">
      <div class="list-name">{{$t('TokenContractAddress')}}</div>
      <input type="text" name="tokenAddress" :placeholder="$t('TokenContractAddress')" v-model="tokenAddress" autocomplete="off">
      <!-- 提交申请 -->
      <a href="javascript:;" class="submit-btn" @click="submitProject">{{$t('Submit')}}</a>
    </form>
  </section>
</template>
<script>
import OssUploader from '@/assets/js/oss.js'
export default {
  data(){
    return {
      title: '',
      cateID: '',
      introduction: '',
      description: '',
      img: '',
      website: '',
      email: '',
      twitter: '',
      telegram: '',
      github: '',
      coingecko:'',
      discord:'',
      coinmarketcap: '',
      scAddress:'',
      shortByte: 0, //简介字符数
      categoryList:null,
      tokenSymbol:"",
      tokenAddress:"",
    }
  },
  created(){
    this.getCategory();
  },
  mounted(){
    // 上传图片
    if(this.$refs.form){
      OssUploader.initUploader('bsc').then(img=>{
        this.img = img;
      })
    }
  },
  methods:{
    getCategory() {
      this.$loading(1);
      this.axios
        .get(this.domain + "getCategory")
        .then((res) => {
          if (res.data.success) {
            this.categoryList = res.data.data.category;
          }
          this.$loading(0);
        })
        .catch((err) => {
          console.log(err);
          this.$loading(0);
        });
    },
    // textarea字符限制
    limitByteSize() {
      this.shortByte = this.webUtil.computeByteSize(this.introduction);
      if (this.shortByte > 256) {
        return false;
      }
      return true
    },
    // textarea高度自适应
    textareaHeight(e) {
      var textarea = e.target
      textarea.style.height = 'auto';
      textarea.style.height = textarea.scrollHeight + 'px';
    },
    validate() {
      if (!this.title.trim()) {
        this.$toast(this.$t('EnterTitle'));
        window.scrollTo(0, this.$refs.title.offsetTop - 74);
        return false;
      }
      if (!this.cateID) {
        this.$toast(this.$t('EnterCategory'));
        window.scrollTo(0, this.$refs.category.offsetTop - 74);
        return false;
      }
      if (!this.introduction.trim()) {
        this.$toast(this.$t('EnterShortIntroduction'));
        window.scrollTo(0, this.$refs.introduction.offsetTop - 74);
        return false;
      }
      if (!this.limitByteSize()) {
        this.$toast(this.$t('EnterIntroductionLimit'));
        window.scrollTo(0, this.$refs.introduction.offsetTop - 74);
        return false;
      }
      return true;
    },
    // 提交表单
    submitProject() {
      if (!this.validate()) {
        return false;
      }

      this.$loading(1);
      var formData = new FormData(this.$refs.form);

      // 去除空文件元素
      try {
        for (var pair of formData.entries()) {
          if (pair[1] instanceof File && pair[1].name == '' && pair[1].size == 0)
            formData.delete(pair[0]);
        }
      } catch (e) {}

      this.axios.post(this.domain + "submitBscProject",formData).then(res=>{
        this.$loading(0);
        if (res.data.success) {
          this.$toast(this.$t('SubmitSuccess')).then(()=>{
            this.$router.push('/');
          })
        } else {
          this.$toast(res.data.message,3000);
        }
      }).catch(err=>{
        this.$loading(0);
        console.log(err);
        this.$toast(this.$t('Error'));
      })
    },
  }
}
</script>
<style scoped>

</style>
